/* global LiveSearch, paginator */

var Sort = new (function () {

  var _this = this,
          sortHolder;

  /**
   * Uses in MobilePaginator -> generateRequestURL
   * (js/jquery/mobile_paginator.js)
   */
  this.getParams = '';

  this.init = function () {
    sortHolder = $('.sorted').eq(0);
    //sortHolder.find('.paging-group button').click(function () {
    sortHolder.find('select').on('change', function () {
      if (typeof paginator === 'object' && paginator !== null) {
        paginatorSetup();
      }

      var sortType = $(this).attr("data-sort");

      if (!sortType) {
        sortType = $(this).val();
      }

      _this.update(sortType);

      if ("undefined" !== typeof LiveSearch) {
        LiveSearch.sort(sortType);
        return false;
      }

      var url = window.location.pathname;

      if (url.substr(-1) !== '/') {
        url += '/';
      }

      if ('pop' !== sortType) {
        url += '?sort=' + sortType;
      }

      $.post(url, {}, function (data) {
        $('#items').html(data.itemsHtml);
        $('.js-paginator').html(data.paginatorHtml);
        window.history.pushState({
          sort: sortType
        }, document.title, url);
      }, 'json');
      return false;
    });
  };

  this.update = function (sort) {
    var priceElement;

    if (!sort) {
      sort = 'pop';
    }

    sortHolder.find("button").removeClass("active");
    priceElement = sortHolder.find(".arrows button");
    sortHolder.find('select').val(sort);
    sortHolder.find('select').trigger('refresh');

    switch (sort) {
      case "priceup":
        priceElement.find("span+span").attr("title", "сначала дешевые");
        priceElement.attr("data-sort", "pricedown");
        priceElement.addClass("active");
        break;
      case "pricedown":
        priceElement.find("span+span").attr("title", "сначала дорогие");
        priceElement.attr("data-sort", "priceup");
        priceElement.addClass("active");
        break;
      default:
        sortHolder.find("button[data-sort=" + sort + "]").addClass("active");
        priceElement.find("span+span").attr("title", "сначала дорогие");
        priceElement.attr("data-sort", "priceup");
    }
  };

});

$('#input-sort').selectbox();
